
          @import "src/components/assets/Variables.module.scss";
        
.FilterSectionContainer {
    width: calc(100% - 48px);
    height: 239px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    position: relative;
    padding: 35px 24px;
    overflow: hidden;
    border-radius: 35px;
    background: rgba(17, 23, 32, 0.83);
    box-shadow: 7px 35px 63px 0px rgba(0, 0, 0, 0.4);
    margin: 24px;

    img {
        position: absolute;
        top: 0;
        right: 0;
        width: 120%;
        height: 330px;
        z-index: 1;
    }

    .Title {
        z-index: 2;
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.25px;
        color: $white;
    }
    .Desc {
        z-index: 2;
        color: $greyShades;
    }
    .Container {
        z-index: 2;
        padding: 0 12px;
        margin-top: 12px;
        display: flex;
        width: 100%;
        max-width: 872px;

        .Search {
            width: 100%;
            max-width: 800px;
            :global {
                .ant-select {
                    width: 100% !important;
                    &-selector {
                        height: 50px;
                        border-radius: 50px !important;
                        border: 1px solid #707986 !important;
                        background: none !important;
                    }
                    &-selection-search-input {
                        height: 50px !important;
                    }
                    &-selection-placeholder {
                        line-height: 50px !important;
                    }
                }
            }
        }

        .DetailsMenuDropdown {
            display: flex;
            background: $backgroundCard;
            align-self: end;
            margin: 0 0 0 27px;
            border-radius: 48px;
            width: 50px;
            height: 50px;
            justify-content: center;
            align-items: center;

            .DetailsMenuButton {
                border-radius: 48px;
                border: 2px solid $border;
                background: $backgroundCard;
            }
            &:hover {
                cursor: pointer;
                .DetailsMenuButton {
                    border-color: $borderGreen !important;
                }
            }

            :global {
                .ant-btn.ant-btn-icon-only {
                    width: 50px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .FiltersWrapper {
            padding: 12px 18px;
            background: $background-paper;
            border: 1px solid $border;
            box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.06);
            border-radius: 8px;
            display: flex;

            .CategoricalFilters {
                display: flex;
                gap: 12px;
                flex-wrap: wrap;

                .CategoricalFilter {
                    min-width: 120px;
                    :global {
                        .ant-select {
                            width: 120px;

                            &-selector {
                                min-height: 40px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.CheckBoxItem {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}

.OverlayContainer {
    background-color: gray;
    max-height: 200px;
    overflow: auto;
}

.DetailsMenuOverlay {
    :global {
        .ant-dropdown-menu {
            border-radius: 13px;
            border: 1px solid $border;
            background: $backgroundCard !important;
            box-shadow: 48px 37px 63px 0px rgba(0, 0, 0, 0.4);
            display: flex;
            width: 404px;
            padding: 30px 30px 30px 30px;
            gap: 12px;
            flex-direction: column;

            &-item {
                color: #ffffff !important;
                margin: 0;
                padding: 0px !important;
                border-bottom: none !important;
                &:hover {
                    background: none !important;
                }
            }
        }

        .ant-select {
            width: 100% !important;
            &-selector {
                height: 60px;
            }
            &-selection-search-input {
                height: 60px !important;
            }
            &-selection-placeholder {
                line-height: 60px !important;
            }
        }
    }
}
