
          @import "src/components/assets/Variables.module.scss";
        
.ImageViewerContainer {
    position: relative;
    width: 100%;
    height: 100%;

    .ImageViewer {
        height: 100%;
        margin: 0 auto;
        position: relative;
    }
}
