
          @import "src/components/assets/Variables.module.scss";
        
.GInputContainer {
    width: 100%;
    border-radius: 50px !important;

    :global {
        .ant-input {
            background: $backgroundCard !important;
            border: 1px solid #707986 !important;
            color: $white;
            width: 100% !important;
            height: 40px;
            padding: 8px 15px !important;
            border-radius: 50px !important;
            &:hover {
                background-color: $backgroundSecondary;
                border: none;
            }

            &:focus {
                box-shadow: none !important;
            }

            &::placeholder {
                color: var(--typography-color-grey, $greyShades);
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.25px;
            }
        }

        .ant-input-sm {
            height: 32px !important;
        }

        .ant-input-lg {
            height: 48px !important;
        }

        .ant-input-number {
            color: $white;
            width: 140px;
            background: $backgroundCard !important;
            border: 1px solid #707986 !important;
            border-radius: 50px !important;
            &:hover {
                background-color: $backgroundSecondary;
            }

            &-handler-wrap {
                background-color: $darkShades;

                .anticon {
                    color: $white;
                }
            }

            &-handler {
                border: 1px solid $backgroundSecondary;
                &:active{
                    background-color: $backgroundSecondary;
                }
            }

            &-focused {
                box-shadow: none !important;
            }
            &-input {
                height: 40px;
            }
        }
        .ant-input-affix-wrapper {
            padding: 0;
            margin: 0;
            background: $backgroundCard;
            border-radius: 50px !important;
            border: 1px solid #707986 ;
        }
        .ant-input-affix-wrapper:hover {
            border-color: #707986 ;
        }
        .ant-input-affix-wrapper-status-success {
            border-color: $success !important;
        }

        .ant-form-item-feedback-icon-success {
            .anticon svg {
                fill: $primary !important;
            }
        }
        .ant-form-item-feedback-icon-warning {
            .anticon svg {
                fill: #faad14 !important;
            }
        }
        .ant-form-item-feedback-icon-error {
            .anticon svg {
                fill: $statusError !important;
            }
        }
        .ant-input-affix-wrapper-focused {
            border-right: none !important;
            box-shadow: none !important;
        }
        .anticon {
            margin-right: 15px;
            svg {
                fill: $iconDefault;
            }
        }
        .ant-input-group-addon {
            background-color: $backgroundCard !important;
            margin: 0;
            padding: 0;
            border: 1px solid #707986 !important;
            border-left: none !important;
        }
        .ant-btn-icon-only {
            background-color: $backgroundCard !important;
            margin: 0;
            padding: 0;
            border: none;
            svg {
                fill: $iconDefault;
            }
        }
    }
}

.GPasswordInput {
    :global {
        input.ant-input {
            border-radius: unset !important;
            padding: 8px;

            border-top-left-radius: 50px !important;
            border-bottom-left-radius: 50px !important;
        }

        .ant-input-group-addon {
            border-top-right-radius: 50px !important;
            border-bottom-right-radius: 50px !important;

            button {
                border-top-right-radius: 50px !important;
                border-bottom-right-radius: 50px !important;
            }
        }

        .ant-input-affix-wrapper {
            padding: 0;
            margin: 0;
            background: $backgroundCard;
            border-top-right-radius: 50px !important;
            border-bottom-right-radius: 50px !important;
            border-right: 1px solid #707986 !important;
            input.ant-input {
                border: none !important;
                outline: none;
            }

            .ant-input-suffix {
                border-top-right-radius: 50px !important;
                border-bottom-right-radius: 50px !important;
            }
        }
    }
}

.GSearchBox {
    @extend .GPasswordInput;
    :global {
        .ant-input-affix-wrapper {
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
            border-right: none !important;
        }

        .ant-input {
            &:hover,
            &:focus {
                border-right: none !important;
            }
        }
    }
}

.PhoneNumber {
    background-color: transparent;
    :global {
        .ant-select-single {
            .ant-select-selector {
                border-right: none !important;
                border-radius: 6px 0px 0px 6px !important;
            }
        }
    }
}

.GInputGroup {
    display: flex;
    align-items: center;

    :global {
        .ant-input {
            border-radius: 0 !important;
        }

        .ant-select {
            &-selector {
                border-radius: 0 !important;
            }
        }
    }
}
