
          @import "src/components/assets/Variables.module.scss";
        
.KeyboardFrame {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $darkShades;
    border: 1px solid $border;
    border-radius: 2px;
    min-width: 32px;
    height: 32px;
    padding: 6px;
}

.Divider{
    margin: 0 4px;
}
