
          @import "src/components/assets/Variables.module.scss";
        
.StudyTable{
    margin: 0 !important;

    :global{
        .ant-table-tbody{
            .ant-table-row{
                .ant-table-cell-row-hover{
                    background-color: $backgroundDark !important;
                };
                cursor: pointer;
            }
            
            .ant-table-expanded-row{
                .ant-table-cell{
                    padding:0;
                    div{
                        .ant-table{
                            border-radius: 0;
                            &-cell{
                                border-radius: 0;
                            }
                            &-cell-row-hover{
                                background-color: $backgroundDark;
                            }
                        }
                        margin: 0;
                        thead{
                            tr{
                                th{

                                    padding: 6px 12px;
                                }
                            }
                        }
                        .ant-table-body{
                            tr{
                                td{

                                    padding: 6px 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}