
          @import "src/components/assets/Variables.module.scss";
        

.ScrollToTopContainer {
    position: absolute;
    left: 30px;
    width: 84px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 8px;
    background: $darkShades90;
    border: 1px solid $border;
    border-radius: 8px;

    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #FFFFFF;
}