
          @import "src/components/assets/Variables.module.scss";
        
.TagContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 15px;
    height: 28px;
    border-radius: 0 16px 16px 16px;
    background-color: #1b222c;

    .Done {
        background-color: #3655f9;
    }

    .BatchDone {
        background-color: $chartColor108;
    }

    .Running {
        margin-right: 8px;
        margin-left: 20px;
        color: #3ca954;
    }

    .Stopped {
        background-color: $greyShades;
    }

    .Failed {
        background-color: $statusError;
    }

    .Partial {
        background-color: #ffbb38;
    }

    .Pending {
        background-color: $white;
    }

    .TagText {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 14px;
        text-align: center;
    }
}

.StatusTag {
    height: 12px;
    width: 12px;
    border: 1px solid #ffffff;
    margin-right: 5px;
    border-radius: 50%;
}

.generateMetricsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        path {
            fill: $secondary100;
        }
    }
}