
          @import "src/components/assets/Variables.module.scss";
        
.StartValidationModal {
    width: 375px !important;
    .Header {
        margin-bottom: 12px;
    }
    .Selected {
        color: $primary;
    }
    .ModelSettings {
        display: flex;
        color: $primary;
        justify-content: center;
    }
}