
          @import "src/components/assets/Variables.module.scss";
        
.ProgressContainer {
    flex-grow: 1;
    width: 328px;
    left: 1448px;
    top: 72px;
    display: flex;
    flex-direction: column;
    background: $backgroundDark;
    border: 1px solid $border;
    padding: 24px;
    grid-gap: 0;
    gap: 0;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    .ProgressBar {
        display: flex;
        align-items: center;

        .Progresslabel {
            flex-basis: 70px;
            margin-right: 12px;
            text-align: start;
            color: #ffffff;
        }
    }

    :global {
        .ant-progress {
            &-outer{
                margin-right: calc(-2em - 4px);
            }
            &-inner {
                border-radius: 6px;
            }

            &-bg {
                height: 16px !important;
                border-radius: 6px 0px 0px 6px;
            }

            &-text {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.4px;
                color: #ffffff;
            }
        }
    }
}