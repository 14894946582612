
          @import "src/components/assets/Variables.module.scss";
        
.CountWrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: center;

    :first-of-type {
        border-left: none !important;
        padding-left: 0 !important;
    }
}

.Title {
    padding-left: 12px;
    border-left: 1px solid $border;
    gap: 4px;
    display: flex;
    align-items: center;
    height: 22px;
}

.Value {
}
