
          @import "src/components/assets/Variables.module.scss";
        
@import 'components/assets/Palette.module.scss';

.TopbarImage {
    background-image: url(../../../../../components/assets/images/MainBackground.png);
    height: 629px;
    position: absolute;
    width: 100%;
    z-index: -1;
}

.TopbarContainer {
    display: flex;
    justify-content: space-between;
    position: relative;

    :global {
        .ant-layout-header {
            display: flex;
            height: 107px;
            padding: 14px 24px;
            align-items: center;
            max-width: 1920px;
            margin: 0 auto;
            width: 100%;
            gap: 24px;
            background: none;
        }
    }
}

.TopbarLeftContainer {
    flex: 1 1 100%;
    display: flex;
    align-items: center;

    .MainLogoWrapper {
        display: flex;
        .MainLogo {
            margin-right: 24px;
            display: flex;
        }
    }

    :global {
        .ant-menu-horizontal {
            .ant-menu-item {
                margin: 10px 0px;
            }

            .ant-menu-submenu {
                padding: 0px;
            }
        }
    }
}

.TopbarRightContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 1 0;
    gap: 12px;

    :global {
        .ant-select {
            &-selection {
                &-item {
                    background: none !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 1120px) {
    .TopbarContainer {
        :global {
            .ant-layout-header {
                max-width: 1100px;
                margin: 0 auto;
            }
        }
        .MainLogo {
            margin-right: 0 !important;
        }
    }
}

@media (max-width: 960px) and (min-width: 900px) {
    .TopbarContainer {
        :global {
            .ant-layout-header {
                gap: 0 !important;
            }
        }
    }
}