
          @import "src/components/assets/Variables.module.scss";
        
.NotificationDropDown {
  display: flex;
}

.DropdownMenu {
  z-index: 1000 !important;
  :global {
    .ant-dropdown-menu {
      display: flex;
      flex-direction: column;
      padding: 0px 16px;
      margin-left: -332px;
      width: 560px;
      min-height: 308px;
      background: $darkShades;
      border: 1px solid $border;
      box-sizing: border-box;
      box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.3);
      border-radius: 0px 0px 8px 8px;
      

      &-item {
        color: #ffffff !important;
        margin: 0;
        padding: 0;

        &:hover {
          background: $darkShades;
        }
      }

      &-title-content {
        width: 100%;
        margin: 0;
        border: none;
      }
    }
  }
}