
          @import "src/components/assets/Variables.module.scss";
        
@import "components/assets/Palette.module.scss";

.NoDataIconContainer {
    position: absolute;
    color: #ffffff;
    text-align: center;
    vertical-align: middle;
    z-index: 10;
    width: calc(100% - 24px);
    height: calc(100% - 80px);
    bottom: 12px;
    left: 12px;
    right: 12px;
    background-color: $background-paper;
    opacity: 0.85;
    :global {
        .nodata-icon {
            margin-top: 150px;
        }
    }
}