
          @import "src/components/assets/Variables.module.scss";
        
@mixin radio-item-text {
    color: #ffffff;
    font-size: 1.4rem !important;
    line-height: 20px !important;
    letter-spacing: 0.75px !important;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500 !important;
    letter-spacing: 0.25px;
}

.RadioGroupContainer {
    :global {
        .ant-radio-wrapper {
            @include radio-item-text;
        }
    }
}

.RadioItem {
    @include radio-item-text;
    :global {
        .ant-radio-checked .ant-radio-inner {
            border-color: $buttonPrimaryDefault;
        }
        .ant-radio-inner::after {
            background-color: $buttonPrimaryDefault !important;
        }
        .ant-radio-input:focus + .ant-radio-inner {
            box-shadow: none !important;
        }
        .ant-radio-disabled + span {
            color: $white;
            opacity: 0.5;
        }
    }
}
