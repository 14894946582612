
          @import "src/components/assets/Variables.module.scss";
        
.NoNotification {
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.NotificationListContainer {
    max-height: 550px;
    overflow: auto;
    position: relative;

    .NotificationItemHeader {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        margin-top: 16px;
        margin-bottom: 16px;

        cursor: pointer;
        gap: 8px;

        svg {
            path {
                fill: $primary;
            }
        }
    }

    .Header {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .CollapsePanelContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .CollapsePanelBadge {
        width: 20px;
        height: 20px;
        background-color: $statusError;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }

    .NotificationItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 14px;
        box-shadow: inset 0px -1px 0px $border;
        cursor: pointer;

        &:hover {
            background: $darkShades90;
            border-radius: 6px;
        }

        .Content {
            display: flex;
            align-items: center;

            .MessageWrapper {
                .Message {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 380px;
                }
            }

            .ReadMark {
                content: ' ';
                width: 10px;
                height: 10px;
                border-radius: 12px;
                margin-right: 6px;
                background: $greyShades;
            }

            .NotRead {
                background: $primary;
            }
        }
    }
}

.NotificationOverlayContainer {
    padding: 16px;
    width: 560px;
    background: $backgroundCard;
    border: 1px solid $border;
    box-shadow: 48px 37px 63px 0px rgba(0, 0, 0, 0.4);
    border-radius: 13px;

    .Header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .Setting:hover {
            svg > path {
                fill: $primary;
            }
        }
    }

    .TabContainer {
        margin-top: unset !important;
        background-color: unset !important;

        :global {
            .ant-tabs {
                &-nav {
                    margin: 0;
                    padding: 16px 24px 0px;

                    &-list {
                        display: grid;
                        grid-template-columns: repeat(3, minmax(0, 1fr));
                        width: 100%;
                    }
                }

                &-tab {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &-content-holder {
                    background: unset !important;
                }
            }
        }
    }

    .ViewNotifications {
        font-family: Roboto;
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 2.5rem;
        color: $primary;
        margin-top: 16px;
        display: block;
    }
}

.CollapseWrapper {
    border: none;
    color: #ffffff;
    background-color: transparent;

    :global {
        .anticon {
            color: #ffffff;
        }

        .ant-collapse {
            &-expand-icon {
                display: flex !important;
                align-items: center !important;

                svg {
                    background: gray;
                    border-radius: 100%;
                    width: 25px;
                    height: 25px;
                    padding: 8px;
                }
            }

            &-item {
                border-bottom: 1px solid $darkShades90 !important;

                &-active {
                    .ant-collapse-header {
                        background: $backgroundDark;
                        border-radius: 8px !important;
                    }
                }
            }

            &-header {
                display: flex !important;
                align-items: center !important;
                height: 68px;
                margin: 0;
                padding: 0;
                color: #ffffff !important;

                &:hover {
                    border-radius: 8px !important;
                }

                &-active {
                    border-radius: 8px !important;
                }
            }

            &-content {
                border-top: 1px solid $border;
                background: $backgroundDark;
                border-radius: 8px !important;

                &-active {
                    border-radius: 8px !important;
                    background: $backgroundDark;
                    border: none !important;
                    margin: 2px 0px;
                }
            }

            &-content-box {
                font-family: Noto Sans;
                font-size: 1.2rem;
                line-height: 16px;
                letter-spacing: 0.4px;
                color: #ffffff;
            }
        }
    }
}

.NotificationDetailsModal {
    width: fit-content !important;
    min-width: 400px;
    max-width: 600px;

    :global {
        .ant-modal-header {
            display: none;
        }

        .ant-modal-body {
            z-index: 1000000;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            gap: 24px;
        }
    }

    .ModalDate {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.4px;
        color: $greyShades;
    }

    .Modalbody {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        word-break: break-word;
    }

    .ModalMessage {
        color: $white;
        font-family: 'Roboto';
        letter-spacing: 0.25px;
        word-break: break-word;
        text-align: center;
        font-size: 16px;
        font-weight: 800;
        max-width: 360px;
    }

    .ModalTitle {
        color: $white;
        font-family: 'Roboto';
        letter-spacing: 0.15px;
        word-break: break-word;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
    }

    .ButtonBox {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 24px;

        .ModalButton {
            flex: 1;

            :global {
                .ant-btn {
                    border-radius: 48px;
                    letter-spacing: 1.25px;
                    font-weight: 700;
                    font-size: 14px;
                    width: 100%;
                    flex: 1;
                }
            }
        }

        .ModalButtonReport {
            flex: 1;

            :global {
                .ant-btn {
                    color: #fff;
                    border-radius: 48px;
                    letter-spacing: 1.25px;
                    font-weight: 700;
                    font-size: 14px;
                }
            }
        }
    }

    .marginBottom {
        margin-bottom: 24px;
    }

    .errorMessage,
    .infoMessage,
    .successMessage {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Roboto';
        gap: 4px;
        padding: 4px 8px;
        color: #fff;
        border-radius: 16px;
        font-weight: 500;
        font-size: 20px;
        line-height: 12px;
    }

    .errorMessage {
        color: $statusError;
    }

    .infoMessage {
        color: $statusInfo;
    }

    .successMessage {
        color: $success;
    }

    .NextStepTitleContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.UploadImage {
    :global {
        .ant-upload-select {
            align-items: center;
            background: transparent !important;
            border: 1px solid $border !important;
            border-radius: 6px !important;
        }

        .ant-upload-list-picture-card {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: 8px;
            justify-content: center;

            &-container {
                width: 100%;
                margin: 0;
            }

            &::before {
                content: none;
            }

            .ant-upload-select-picture-card {
                width: 100%;
                margin: 0;
            }

            .ant-upload {
                color: $white;
            }

            .ant-upload-list-item {
                border: 1px solid $border !important;
                border-radius: 6px !important;
            }
        }
    }

    .PreviewImage {
        position: relative;
    }

    .DeleteIcon {
        color: #ffffff;
        position: absolute;
        left: 0;
        right: 0;

        cursor: 'pointer';
        font-size: 18px;
    }
}

.AdditionalInfo {
    width: 100%;
}

@media screen and (max-width: 1000px) {
    .NotificationOverlayContainer {
        width:450px;
    }
}