
          @import "src/components/assets/Variables.module.scss";
        
.OAuthContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;

    button {
        width: 307px;
        margin:auto;
        span {
        svg {
            path {
                fill: $white !important;
            }
        }
    }
    }
}

.Divider {
    color: $greyShades !important;
    border-top-color: $greyShades !important;
}
