
          @import "src/components/assets/Variables.module.scss";
        
.GSlider:hover,
.GSlider {
    :global {
        .ant-slider {
            &-handle {
                width: 2rem;
                height: 2rem;
                background: $darkShades;
                border: 2px solid #ffffff;
                box-sizing: border-box;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
                right: -2px;
            }
            &-track,
            &-rail {
                background-color: $darkShades80;
                width: 2px;
            }

            &-mark-text {
                color: #ffffff;
            }
        }

        .ant-slider-handle:not(.ant-tooltip-open) {
            border-color: $borderGreen;
        }
    }
}
