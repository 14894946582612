
          @import "src/components/assets/Variables.module.scss";
        
.TagListContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;

    .InfoTagItem {
        text-align: center;
        border-radius: 0px 16px 16px 16px;
        color: $white;
        background: $darkShades90;
        text-transform: capitalize;
        width: unset;
        max-width: 140px;
        align-items: center;
        padding: 6px 15px;
        height: 28px;
    }
}

.StatsListContainer {
    display: flex;
    gap: 8px;
    margin: 23px 20px;

    .StatsItem {
        height: 28px;
        display: flex;
        align-items: stretch;
        color: $greyShades;
        align-items: center;
        padding: 4px 12px;
        border-radius: 36px;
        border: 1px solid $border;
        width: 100%;
        justify-content: center;

        .icon {
            margin: 5px 8px 0 0;

            svg > path {
                fill: $white;
            }
        }
    }
}

.vertical {
    flex-direction: column;
}
