
          @import "src/components/assets/Variables.module.scss";
        
.LoginRegisterSwitchContainer {
    width: 50%;

    margin-bottom: 18px;

    :global {
        .ant-radio-button-wrapper {
            background: none;
            border: none;
            color: $greyShades;

            width: 50%;
            height: unset;
            text-align: center;
            padding: 10px 12px;

            &::before {
                display: none;
            }

            &-checked {
                color: $primary;
                border-bottom: 4px solid $borderGreen !important;
                &:hover {
                    color: $primary;
                }
            }
        }
    }
}
