
          @import "src/components/assets/Variables.module.scss";
        
.Tooltip {
    :hover {
        cursor: pointer;
    }
    :global {
        .ant-tooltip-content {
            filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.4));
        }
    }
}
