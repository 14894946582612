
          @import "src/components/assets/Variables.module.scss";
        
.FallbackContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: auto;
    width: 100%;
    height: 100vh;

    :global {
        .ant-spin-text {
            margin-top: 24px;
            color: $white;
            font-family: Roboto;
            font-size: 1.6rem;
        }
    }
}

.AllScreen {
    width: 100vw;
}
