
          @import "src/components/assets/Variables.module.scss";
        
.Breadcrumb {
    padding: 24px 24px 0px 36px;
    background: linear-gradient(131deg, rgba(24, 31, 41, 0.93) 64.58%, #070c15 97.92%, #070c15 78.71%);
    border-radius: 35px 34px 0 0;
    margin: 0px 24px;

    :global {
        .ant-breadcrumb-link {
            a {

                color: $typographyGrey !important;
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 34.638px;
                letter-spacing: 0.236px;
            }
        }

        :hover {
            color: #bfc8d6 !important;
        }

        ol :last-child {
            .ant-breadcrumb-link,a {


                color: $white !important;
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 34.638px;
                letter-spacing: 0.236px;
            }

            :hover {
                color: $white !important;
            }

        }

        .ant-breadcrumb-separator {
            color: $typographyGrey;
            width: 20px;
            height: 20px;
        }
    }
}