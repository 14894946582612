
          @import "src/components/assets/Variables.module.scss";
        
@import 'components/GButton/Styles.module.scss';

.ModalContainer {
    box-shadow: 7px 35px 63px 0px rgba(0, 0, 0, 0.75), -7px -35px 63px 0px rgba(0, 0, 0, 0.8);

    :global {
        .ant-modal-close {
            display: none;
        }

        .ant-modal {
            &-header {
                background-color: $backgroundCard !important;
                border-bottom: 1px solid #707986;
                padding: 0 0 20px 0;
            }

            &-content {
                background-color: $backgroundCard !important;
                border-radius: 8px;
                border: 2px solid $borderGreen;
                padding: 20px;
            }

            &-title {
                color: $white;
                display: flex;
                align-items: center;

                .close-icon {
                    cursor: pointer;
                    width: 24px;
                    height: 24px;

                    &:hover {
                        path {
                            fill: $primary;
                        }
                    }
                }

                .title {
                    flex-grow: 1;
                }
            }

            &-footer {
                background-color: $darkShades !important;
                border-top: none;
            }

            &-body {
                padding: 24px 0 0;

                .ant-form-item.button-wrapper {
                    margin-top: 25px;
                }

                .ant-form-vertical {
                    .ant-form-item.button-wrapper {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .ModalHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex-direction: row;
        gap: 8px;
    }
}

.LeftIcon {
    flex-direction: row-reverse !important;

    :global {
        .close-icon {
            margin-left: 0 !important;
        }
    }
}

:global {
    .ant-modal-mask {
        background: rgba(0, 0, 0, 0.52);
        backdrop-filter: blur(10px);
    }
}
