
          @import "src/components/assets/Variables.module.scss";
        
.CarouselContainer {
    background: #1a1a1a;
    height: 100%;
    width: 50vw;

    :global {
        .ant-carousel {
            height: 100%;
            .slick-dots-bottom {
                bottom: 60px;
                .slick-active {
                    width: 48px;
                }
                li {
                    width: 24px;
                }
            }
        }
    }

    .Carousel1 {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        height: 100vh;
        .Image1 {
            width: 85%;
            margin-bottom: 48px;
        }
        .Message {
            text-align: center;
            padding: 0 10%;
        }
    }
    .Carousel2 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100vh;
        .Image2 {
            width: 85%;
            margin: 40px 0 60px;
        }
        .Message {
            text-align: center;
            padding: 0 18%;
        }
    }
}
