
          @import "src/components/assets/Variables.module.scss";
        
@import 'components/assets/Palette.module.scss';

.CardContainer {
    position: relative;
    height: 400px;
    @extend .CardBg;

    display: flex;
    flex-direction: column;

    :global {
        .g-card-content {
            min-height: 0;
        }
        .g-card-header {
            display: flex;
            margin: 24px;
            align-items: center;
            .card-caption {
                color: $white;
                flex-grow: 1;
                align-items: center;
                display: flex;

                .title {
                    margin-right: 12px;
                    white-space: nowrap;
                }
            }
        }

        .ant-spin-container,
        .g-loading-wrapper {
            height: 100%;
        }
    }

    .NoDataIconContainer {
        position: absolute;
        color: $white;
        text-align: center;
        vertical-align: middle;
        z-index: 10;
        width: calc(100% - 24px);
        height: calc(100% - 80px);
        bottom: 12px;
        left: 12px;
        right: 12px;
        background-color: $backgroundDark;
        opacity: 0.75;
        :global {
            .nodata-icon {
                margin-top: 150px;
            }
        }
    }
}
