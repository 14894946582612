
          @import "src/components/assets/Variables.module.scss";
        
.TagInputContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    span {
        font-family: Roboto;
        font-size: 1.4rem !important;
        line-height: 20px;
        letter-spacing: 0.25px !important;
        color: #ffffff !important;
    }
    .InputContainer {
        display: flex;
        align-items: center;
        .GInputTags {
            border-bottom-right-radius: 0px !important;
            border-top-right-radius: 0px !important;
        }

        .FormPlusIcon {
            margin-top: 5px;
            :hover {
                cursor: pointer;
            }
        }
    }

    .InputTagContainer{
        flex-wrap: wrap;
    }

    :global{
        .ant-tag{
            font-size: 1.2rem !important;
            font-weight: normal !important;
            letter-spacing: 0.4px !important;
        }
    }

}
