
          @import "src/components/assets/Variables.module.scss";
        
.SkeletonItem {
    overflow: hidden;
    width: 100%;

    :global {
        .ant-skeleton {
            width: 100%;
            &-image {
                background: $darkShades90;
                border-radius: 6px;
                width: 100%;
                margin-bottom: 8px;
            }

            &-title {
                background: $darkShades90;
                border-radius: 4px;
            }
        }
    }
}

.DashboardCardSkeletonContainer {
    border: 1px solid $border;
    border-radius: 8px;
    :global {
        .ant-skeleton {
            background: $darkShades;
            padding: 24px;

            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        img,
        .ant-image {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
    }
}
