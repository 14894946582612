
          @import "src/components/assets/Variables.module.scss";
        
@mixin Cell-Status {
    width: 10px;
    height: 10px;
    margin: 0px 6px;
    border-radius: 50%;
    min-width: 10px;
    min-height: 10px;
}

.MenuItem {
    display: flex;
    align-items: center;
    :global {
        .STOP,
        .DOWN {
            @include Cell-Status;
            background-color: $greyShades;
        }
        .UP {
            @include Cell-Status;
            background-color: $statusSuccess;
        }
        .INPROGRESS {
            @include Cell-Status;
            background-color: #ffc000;
        }
        .FAILED {
            @include Cell-Status;
            background-color: $statusError;
        }

        .smart_annotation {
            @include Cell-Status;
            background-color: $statusSuccess;
        }
    }

    height: 30px;

    svg {
        overflow: visible;
    }
}

.CompatibleSwitch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid $darkShades80;
}
