
          @import "src/components/assets/Variables.module.scss";
        
.DatasetExplorer {
    :global {
        .truncate {
            width: 200px;
        }
        .ant-empty {
            color: $white;
        }
    }

    .ImageContainer {
        width: 100%;
        height: 140px;
        text-align: center;
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: center;



        :global {
            .ant-image {
                width: 100%;
                height: 100%;

                &-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    border: 1px solid $borderLight;
                    border-radius: 6px;
                }
            }
        }
    }

    .StackedThumbnail {
        margin-bottom: 18px;
        :global {
            .ant-image {
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

                .ant-image-img {
                    position: relative;
                }
            }
        }
    }
}

.ImageSelection {
    position: absolute;
    z-index: 100;
    top: 8px;
    left: 8px;
}

.borderGreen {
    border: 1px solid $statusSuccess !important;
}

.borderRed {
    border: 1px solid $statusError !important;
}

.borderYellow {
    border: 1px solid $statusWarning !important;
}

.borderBlue {
    border: 1px solid $statusInfo !important;
}

.InfoIcon{
    position: absolute;
    top: 8px;
    right: 5px;
    z-index: 9;
}
