
          @import "src/components/assets/Variables.module.scss";
        
.ProgressContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 202px;
    padding: 10px;
    background: #1C222B;
    border-radius: 30px;

    .Percentage {
        margin-left: 5px;
        font-size: 13px;
        color: $white;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.13px;
    }
}

.ProgressBar {
    position: relative;
    width: 145px;
    height: 13px;
    background-color: #272D39;
    border-radius: 6px;
    overflow: hidden;
}

.ProgressFill {
    height: 100%;
    background: linear-gradient(94deg, #0be197 7.21%, #7431ef 85.07%);
    border-radius: 6px;
    width: 0;
    transition: width 0.3s ease;
}