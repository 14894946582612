
          @import "src/components/assets/Variables.module.scss";
        
@mixin Menu-Item-Content {
    color: #ffffff;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
}

.NavMenu {
    flex: 1 1 100%;
    min-width: 0;
    align-items: center;
    max-width: 50vw;

    li:last-child:after {
        display: none;
    }

    :global {
        .ant-menu-item::after,
        .ant-menu-submenu::after {
            border-bottom: none !important;
        }

        .ant-menu-item {
            height: 50px;
            margin-top: 10px;

            .ant-menu-title-content {
                @include Menu-Item-Content;
                a,
                a:hover {
                    @include Menu-Item-Content;
                }
            }
        }


        .ant-menu-submenu {
            .ant-menu-submenu-title {
                height: 52px;
                padding: 16px 16px;
                display: flex;
                // margin: 10px 0px;
                border-radius: 8px;
                svg{
                    color: #fff ;
                }
                &:hover {
                    color: #fff ;

                    path {
                        fill: #fff ;
                    }
                }
            }
        }
   
    }
}
:global {
    .ant-menu-title-content {
        @include Menu-Item-Content;
        a,
        a:hover {
            @include Menu-Item-Content;
        }
    }

}
