
          @import "src/components/assets/Variables.module.scss";
        
.SessionReminderContainer {
    .Icon {
        margin: 0px auto 12px;
    }
    
    .Text,
    .Counter {
        text-align: center;
    }

    .ButtonContainer {
        display: flex;
        align-items: center;
        margin-top: 12px;
        gap: 12px;
        .Button {
                width: 100%;
        
                :global {
                    .ant-btn {
                        width: 100%;
                    }
                }
            }
    }

    :global {
        .ant-modal-body {
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
    }
}
