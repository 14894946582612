
          @import "src/components/assets/Variables.module.scss";
        
.SwitchContainer {
  :global {
    .ant-switch {
      background: $backgroundDark;
      outline: 1px solid $border;
      box-sizing: border-box;
      border-radius: 15px;
      &-checked {
        background: $primary;
      }
      &-checked:focus {
        box-shadow: none;
      }
    }
  }
}
