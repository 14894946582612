
          @import "src/components/assets/Variables.module.scss";
        
.LayoutContainer {
    background: none !important;

    :global {
        .ant-layout-content {
            width: 100%;
            max-width: 1920px!important;
            margin: 0 auto;
        }
    }
}

.MobileWarningModal{
    display: flex !important;
    justify-content: center !important;
    margin: 0 auto !important;
    box-shadow: none;
    top: 30%;
}
.ModalContentContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    justify-content: center;
}

.ModelContentTitle{
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
}
.MobileBackground{
    background: url(../../../components/assets/images/dashboard.webp) no-repeat center center fixed;
    background-size: cover;
}

@media only screen and (max-width: 1920px) {
    .LayoutContainer {
        :global {
            .ant-layout-content {
                margin: 0;
            }
        }
    }
}