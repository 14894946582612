
          @import "src/components/assets/Variables.module.scss";
        
.ActivationModalContainer {
  :global {
    .ant-image {
      border: 1px solid #c5cdd9;
      border-radius: 6px;

      &-img {
        border-radius: 6px;
      }
    }

    .ant-modal-body {
      min-height: 200px;
    }
  }
}

.ActivationExpiredModalContainer {
  @extend .ActivationModalContainer;

  :global {
    .ant-modal-body {
      min-height: 70px;
    }
  }
}

.FormContainer {
  :global {
    .ant-form {
      gap: 12px;

      .ant-form-item-control-input {
        width: 100%;

        .button {
          width: 100%;

          .ant-btn {
            width: 100%;
          }
        }

      }
    }
  }
}