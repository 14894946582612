
          @import "src/components/assets/Variables.module.scss";
        
.DatasetTable {
  width: 100%;
  margin: 0;
  :global {
    .ant-table-thead {
      .ant-table-cell {
        font-size: 12px;
      }
    }
  }
}
