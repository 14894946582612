
          @import "src/components/assets/Variables.module.scss";
        
.g-grid-container {
    padding: 24px;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 24px;
    width: 100%;
}

@media only screen and (max-width: 1200px) {
    .g-grid-container {
        display: flex;
        flex-direction: column;
    }
}
