
          @import "src/components/assets/Variables.module.scss";
        
.MobileWarningModal {
    display: flex !important;
    justify-content: center !important;
    margin: 0 auto !important;
    box-shadow: none;
    top: 30%;
}

.ModalContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    justify-content: center;

    .Title {
        text-align: center;
        margin-bottom: 12px;
    }

    .Body {
        text-align: center;
    }
}
