
          @import "src/components/assets/Variables.module.scss";
        
.GStepsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    width: 100%;

    :global {
        .step-title {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            color: $white;
            gap: 16px;

            .step {
                display: flex;
                padding: 12px 0;
                justify-content: center;
                align-items: center;
                gap: 10px;
                color: $typographyGrey;
                text-align: center;
            }

            .active {
                color: white;
            }

            .solid {
                margin: 0;
                border: 1px solid $background-paper;
                width: 48px;
            }
        }

        .step-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .disabled {
            cursor: not-allowed;
            button {
                cursor: not-allowed;
            }
        }
    }

    .StepIndex {
        display: flex;
        width: 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        border: 1px solid $greyShades;
        color: $greyShades;
        text-align: center;
        font-size: 12px !important;
    }
    .ActiveIndex {
        background: white;
        color: $typographyDark;
    }
}

.StepButtons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;

    :global {
        .disabled {
            cursor: not-allowed;
            button {
                cursor: not-allowed;
                background-color: $buttonPrimaryDisabled !important;
                color: $typographyDisabled !important;
            }

            :hover {
                color: $typographyDisabled !important;
            }

            .text {
                &:hover {
                    color: $typographyDisabled !important;
                    cursor: not-allowed;
                }
            }
        }
    }
}

.LeftArrow {
    display: flex;
    svg {
        transform: rotate(180deg);
        path {
            fill: $white !important;
        }
    }
}
