
          @import "src/components/assets/Variables.module.scss";
        
.AuthForm {
    width:100%;
    
    button {
        width: 307px;
        margin:auto;
    }

    :global {
        .ant-form {
            gap: 12px;
        }
    }
}
