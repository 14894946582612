
          @import "src/components/assets/Variables.module.scss";
        
.DashboardCaptionContainer {
    border-radius: 8px;
    color: $white;
    margin: 24px;

    .OptionsWrapper {
        display: flex;
        justify-content: space-between;
        gap: 12px;
        padding: 16px 0;

        .LeftSideWrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 12px;

            .Filters {
                max-width: 1000px;
                :global {
                    .ant-tabs {
                        &-nav {
                            margin: 0px;
                            padding: 0;
                            &::before {
                                border: none !important;
                            }

                            .ant-tabs-nav-add {
                                background: $background-paper;
                                color: $white;
                                border-radius: 6px 6px 0px 0px;
                                height: 40px;
                                align-self: flex-end;

                                &:hover,
                                &:active,
                                &:focus {
                                    color: $primary50;
                                }
                            }

                            &-list {
                                gap: 12px;
                            }
                        }

                        &-tab {
                            &-active {
                                border-bottom: 1px dashed var(--Status-Color-Info, #8f00ff) !important;
                                color: $white !important;

                                .ant-tabs-tab-btn {
                                    color: $greyShades !important;
                                }
                            }
                            padding: 10px 20px 10px !important;
                            height: 40px !important;
                            background-color: unset !important;
                            border-radius: 50px !important;
                            border: 1px dashed var(--Status-Color-Info, #8f00ff) !important;
                            color: $greyShades !important;

                            .ant-tabs-tab-remove {
                                color: $white;

                                &:hover,
                                &:active,
                                &:focus {
                                    color: $greyShades !important;
                                }
                            }
                        }

                        &-content-holder {
                        }

                        &-tab-remove:focus,
                        &-tab-btn:active,
                        &-tab-remove:active {
                            color: $greyShades;
                        }
                    }

                    .ant-tabs-nav-operations {
                        .anticon-ellipsis {
                            color: $white;
                        }
                    }
                }
            }
        }

        .RightSideWrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 12px;

            .GSelectWrapper {
                display: flex;
                align-items: center;
                gap: 8px;

                :global {
                    .ant-select {
                        &:not(.ant-select-customize-input) .ant-select-selector {
                            background: none !important;
                        }

                        &-selector {
                            width: 192px;
                        }

                        &-selection-item {
                            background: none !important;
                        }
                    }
                }
            }

            .IconWrapper {
                display: flex;
                gap: 12px;

                .Icon {
                    color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 50px;
                    cursor: pointer;

                    &:hover {
                        border: 2px solid $borderGreen;
                    }

                    background: $backgroundDark;
                }

                .Active {
                    background: $primary;
                    svg {
                        path {
                            fill: $black;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1800px) {
    .Filters {
        max-width: 800px !important;
    }
}

@media only screen and (max-width: 1600px) {
    .Filters {
        max-width: 600px !important;
    }
}

@media only screen and (max-width: 1400px) {
    .Filters {
        max-width: 400px !important;
    }
}

@media only screen and (max-width: 1200px) {
    .Filters {
        max-width: 200px !important;
    }
}
