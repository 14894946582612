
          @import "src/components/assets/Variables.module.scss";
        
.GDicomViewer {
    display: grid;
    min-height: 0;
    height: 100%;

    .Viewport {
        border: 1px solid $border;
    }

    .Active {
        border: 1px solid $secondary100;
    }

    .CornerstoneViewport {
        height: 100%;
        width: 100%;
    }

    :global {
        .viewport-wrapper {
            padding: 3px;
        }
    }
}

.ViewportLoadingWrapper {
    height: 100%;

    :global {
        .ant-spin {
            min-height: 100%;
            min-width: 100%;
        }
        div:has(> div.ant-spin) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        div > .ant-spin {
            background: unset;
        }

        .ant-spin-container {
            height: 100%;
        }
    }
}

.grid {
    padding: 10px;
    background-color: $darkShades;

    display: grid;
    grid-template-columns: repeat(3, 1fr);

    div {
        border: 1px solid $background-paper;
        width: 25px;
        height: 25px;
        color: $white;
        &:hover {
            cursor: pointer;
        }
    }
}
