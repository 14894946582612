
          @import "src/components/assets/Variables.module.scss";
        
.SelectMenu {
    background: $backgroundCard !important;
    border: 1px solid $border !important;
    width: 221px;
    padding: 0px;
    color: $white;
    border-radius: 13px;
    background: var(--fill-color-dark, $backgroundCard);
    box-shadow: 48px 37px 63px 0px rgba(0, 0, 0, 0.4);

    :global {
        .ant-select {
            &-item {
                color: $white !important;

                &:hover {
                    background: $darkShades90 !important;
                }
                background: $backgroundDark !important;
            }

            &-title-content {
                width: 100%;
                height: 30px;
                padding: 6px 24px;
                margin: 0;
                border: none;
            }
        }
        .ant-empty {
            color: $white;
        }
        .ant-input {
            height: 32px;
        }
        .ant-input-suffix {
            display: none;
        }
    }
}

.SelectContainer {
    position: relative;
    :global {
        .ant-select {
            &-selector.ant-select-arrow {
                background: $backgroundCard;
                border: 1px solid $borderLight;
                border-radius: 50px;
                color: $white;
                align-items: center;
            }
            &-selector::after {
                display: none;
            }

            &-arrow {
                color: $white;
                right: 15px !important;
            }

            &-clear {
                color: $statusError;
                background-color: $backgroundCard !important;
                right: 15px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                top: 40%;
            }

            &-selection {
                &-item {
                    background: $backgroundCard !important;

                    border: none;
                    ::after {
                        display: none;
                    }
                }

                &-item::after {
                    display: none !important;
                }

                &-placeholder {
                    color: $greyShades;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                    right: 15px;
                    left: 15px;
                }
            }

            .anticon-close {
                color: $white !important;
            }

            &-open {
                .ant-select-arrow {
                    path {
                        stroke: $primary;
                    }
                }
            }
            &-multiple {
                .ant-select {
                    &-selector {
                        padding: 1px 7px;
                    }
                    &-selection-search {
                        margin-inline-start: 8px;
                    }
                }
            }

            &-single {
                .ant-select {
                    &-selector {
                        .ant-select-selection-search {
                            left: 15px;
                        }
                    }
                }
            }
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background: $backgroundCard !important;
            border: 1px solid $borderLight;
            color: $white;
            align-items: center;
            border-radius: 50px;
            overflow: hidden;
            height: 40px;
        }

        .ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
            height: 48px;
        }

        .ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
            height: 32px;
        }

        .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
            height: 100%;
        }

        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border-color: $borderLight;
            box-shadow: none;
        }

        .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector,
        .ant-select-sm .ant-select-selector {
            height: 32px;
        }

        .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
            height: 48px;
        }

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            height: 40px;
            padding: 0 15px;
        }
    }
}

.Loading {
    padding: 12px;
}

.AddItem {
    color: $primary;
    svg {
        path {
            fill: $primary;
        }
    }
}


.Wrapper {
    padding: '0 8px 4px'
}