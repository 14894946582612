
          @import "src/components/assets/Variables.module.scss";
        
.UploadImageContainer {
    :global {
        .ant-upload {
            &-select {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                height: 100%;
                background: $backgroundCard !important;
                border: 1px dashed $darkShades70 !important;
                border-radius: 2px !important;
                padding: 24px 24px 32px 24px;
            }
        }
        .ant-upload.ant-upload-select-picture-card {
            margin: 0;
        }

        .ant-upload-list-picture-card .ant-upload-list-item {
            border: none;
        }
    }

    .PreviewImage {
        position: relative;
    }
    .DeleteIcon {
        color: #ffffff;
        position: absolute;
        left: 0;
        right: 0;

        cursor: 'pointer';
        font-size: 18px;
    }
}
