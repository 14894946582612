
          @import "src/components/assets/Variables.module.scss";
        
.DataFramework{
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  background: $greyShades;
  border-radius: 16px;
  align-self: center;
  margin: 0 10px;
}