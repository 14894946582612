
          @import "src/components/assets/Variables.module.scss";
        
@import "components/assets/Palette.module.scss";

.SelectMenu {
    background: $backgroundDark !important;
    border: 1px solid $border !important;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.06);
    width: 221px;
    padding: 0px;
    color: #ffffff;

    :global {
        .ant-select-tree {
            &-treenode {
                color: #ffffff !important;

                background: $backgroundDark !important;
            }

            &-node-content-wrapper {
                &:hover {
                    background: $darkShades90 !important;
                }
                background: $backgroundDark !important;
            }
            
            &-node-selected {
                background: $darkShades90 !important;
            }

            &-title-content {
                width: 100%;
                height: 30px;
                padding: 6px 24px;
                margin: 0;
                border: none;
            }
        }
        .ant-empty {
            color: #ffffff;
        }
        .ant-input {
            height: 32px;
        }
        .ant-input-suffix {
            display: none;
        }

        .ant-select-tree-checkbox {
            border: 1.5px solid #c5cdd9;
            border-radius: 4px;

            &-inner {
                background-color: transparent;
                border: none;
            }

            &-checked {
                background-color: $primary;
                border: 1.5px solid $primary;
                .ant-checkbox-inner::after {
                    border: 2px solid $black;
                    border-top: 0;
                    border-left: 0;
                }
            }

            &-indeterminate .ant-select-tree-checkbox-inner::after {
                background-color: $primary;
            }
        }
    }
}

.SelectContainer {
    position: relative;
    :global {
        .ant-select {
           
            &-selector {
                background: $backgroundCard !important;
                border: 1px solid $borderLight;
                border-radius: 50px !important;
                color: #ffffff;
                align-items: center;
            }
            &-selector::after {
                display: none;
            }

            &-arrow {
                color: $white;
                right: 15px !important;
            }

            &-clear {
                color: $statusError;
                background-color: $backgroundCard !important;
                right: 15px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                top: 40%;
            }

            &-selection-item {
                background: $backgroundCard !important;
                border: none;
                ::after {
                    display: none;
                }
            }
            &-selection-item::after {
                display: none !important;
            }
            .anticon-close {
                color: #ffffff !important;
            }

            &-open {
                .ant-select-arrow {
                    path {
                        stroke: $primary;
                    }
                }
            }
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background: $backgroundCard !important;
            border: 1px solid $borderLight;
            color: $white;
            align-items: center;
            border-radius: 50px;
        }

        .ant-select-show-search.ant-select:not(.ant-select-customize-input)
            .ant-select-selector
            input {
            height: 100%;
        }
    }
}

.Loading {
    padding: 12px;
}

