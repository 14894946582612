
          @import "src/components/assets/Variables.module.scss";
        
a {
    cursor: pointer;

    .BaseCell {
        &:hover {
            color: $primary;
            text-decoration: underline;

            .CellSummary {
                color: $primary;
            }
        }
    }
}

.BaseCell {
    color: $white;

    .CellSummary {
        color: $greyShades;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 18px;
        overflow-wrap: break-word;
        letter-spacing: 0.4px;
    }
}