
          @import "src/components/assets/Variables.module.scss";
        
.TabContainer {
    :global {
        .ant-tabs {
            &-nav {
                margin: 0px;
                padding: 24px 24px 0px 24px;
                &::before {
                    border: none !important;
                }

                .ant-tabs-nav-add {
                    background: $background-paper;
                    color: $white;
                    border-radius: 6px 6px 0px 0px;
                    height: 40px;
                    align-self: flex-end;

                    &:hover,
                    &:active,
                    &:focus {
                        color: $primary50;
                    }
                }
            }

            &-tab {
                padding: 10px 12px 12px !important;
                height: 46px !important;
                background-color: unset !important;
                border: none !important;
                color: $greyShades !important;

                &-active {
                    border-bottom: 4px solid $primary !important;
                    color: $white !important;

                    .ant-tabs-tab-btn {
                        color: $primary;
                    }
                }

                .ant-tabs-tab-remove {
                    color: $white;

                    &:hover,
                    &:active,
                    &:focus {
                        color: $greyShades !important;
                    }
                }
            }

            &-content-holder {
            }

            &-tab-remove:focus,
            &-tab-btn:active,
            &-tab-remove:active {
                color: $greyShades;
            }
        }

        .ant-tabs-nav-operations {
            .anticon-ellipsis {
                color: $white;
            }
        }
    }
}

:global {
    .ant-tabs-dropdown-menu-item,
    .ant-tabs-dropdown-menu {
        background-color: $backgroundTertiary !important;
        color: $white;
        border-radius: 10px;
    }
    .ant-tabs-dropdown-menu {
        border: 1px solid $border;
    }
}
