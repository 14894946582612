
          @import "src/components/assets/Variables.module.scss";
        
.FileUploadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px dashed $border !important;
    border-radius: 6px !important;
    padding: 24px;
    cursor: pointer;
    position: relative;

    .UploadFileText {
        color: #ffffff;
        margin: 6px 0px 0;
    }

    :global {
        .image-preview {
            padding-bottom: 12px;

            .delete-icon {
                cursor: pointer;
                position: absolute;
                left: 47%;
            }
        }
    }
}

.FileListContainer {
    background: $backgroundDark;
    border: 1px solid $border;
    box-shadow: 0px 4px 13px rgba(118, 128, 143, 0.3);
    border-radius: 6px;
    padding: 12px;
    margin-top: 18px;

    display: flex;
    align-items: center;

    .FileItemContainer {
        flex-grow: 1;
        margin-left: 12px;
        color: #ffffff;

        display: flex;
        align-items: flex-end;

        :global {
            .delete-icon {
                cursor: pointer;
            }

            .cancel-icon {
                color: #ff0000;
                cursor: pointer;
                margin-left: 6px;
            }
        }
    }

    .ProgressContainer {
        flex-grow: 1;

        :global {
            .ant-progress {
                width: 100%;

                &-text {
                    color: #ffffff;
                }
            }
        }
    }
}