@import './Typography.module.scss';
@import './Variables.module.scss';

:root {
    font-family: Roboto !important;
    font-style: normal !important;
}

.primary {
    color: #00ffa7;
}

.white {
    color: white;
}

.tertiary {
    color: $tertiary !important;
}

.secondary {
    color: $secondary100;
}

.primary {
    color: $primary;
}

.red {
    color: $statusError;
}
.black {
    color: black;
}

.grey {
    color: $greyShades; //#b0b8c5;

}
.violet{
    color: $violet;
}

.iconDefault {
    color: $iconDefault;
}

.pointer {
    cursor: pointer;
}

.not-allowed {
    cursor: not-allowed;
}

.flex {
    display: flex;
}

.space-between {
    justify-content: space-between;
}

.flex-align {
    display: flex;
    align-items: center;
}

.wrap {
    flex-wrap: wrap;
}

.flex-baseline {
    display: flex;
    align-items: baseline;
}

.flex-align-end {
    display: flex;
    align-items: flex-end;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-justify-end {
    display: flex;
    justify-content: flex-end;
}

.gap4 {
    gap: 4px;
}

.gap6 {
    gap: 6px;
}

.gap8 {
    gap: 8px;
}

.gap12 {
    gap: 12px;
}
.gap15{
    gap: 15px;
}

.gap18 {
    gap: 18px;
}

.gap20 {
    gap: 20px;
}

.gap24 {
    gap: 24px;
}

.visible {
    overflow: visible;
}

.overflow-hidden {
    overflow: hidden;
}

.text-center {
    text-align: center;
}

.truncate {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.capitalize {
    text-transform: capitalize;
}

.underline {
    text-decoration-line: underline !important;
}

.m-12 {
    margin: 12px;
}

.m-24 {
    margin: 24px;
}

.mb-12 {
    margin-bottom: 12px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mt-12 {
    margin-top: 12px;
}
.mt-16 {
    margin-top: 16px;
}
.mb-16 {
    margin-top: 16px;
}

.mt-24 {
    margin-top: 24px;
}

.mb-4 {
    margin-bottom: 4px;
}

.mt-4 {
    margin-top: 4px;
}

.mb-8 {
    margin-bottom: 8px;
}

.mtb-8 {
    margin-top: 8px;
    margin-bottom: 8px !important;
}

.ml-8 {
    margin-left: 8px;
}

.mr-8 {
    margin-right: 8px;
}

.p-12 {
    padding: 12px;
}

.p-24 {
    padding: 24px;
}

.text-nowrap {
    text-wrap: nowrap;
}

.no-wrap {
    white-space: nowrap;
}

.relative {
    position: relative;
}