
          @import "src/components/assets/Variables.module.scss";
        
.VersionContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .PlatformVersion {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}