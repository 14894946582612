
          @import "src/components/assets/Variables.module.scss";
        
.ActivationModalContainer {
    :global {
        .ant-image {
            border: 1px solid #c5cdd9;
            border-radius: 6px;

            &-img {
                border-radius: 6px;
            }
        }

        .ant-modal-body {
            min-height: 200px;
        }
    }
}
