
          @import "src/components/assets/Variables.module.scss";
        
.loading {
    filter: blur(5px);
}

.Spin {
    :global {
        div > .ant-spin {
            background: #161b24;
        }

        .ant-spin {
            color: $white;
        }
    }
}
