
          @import "src/components/assets/Variables.module.scss";
        
.Version {
    margin-right: 48px;
    &:hover,
    &:focus,
    &:active {
        cursor: pointer;
    }
}