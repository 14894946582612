
          @import "src/components/assets/Variables.module.scss";
        
.LegendContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    color: white;
    margin-top: 12px;
    margin-left: 60px;
    overflow-y: auto;
    max-height: 25px;

    .LegendItem {
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;

        &:hover{
            opacity: 0.8;
        }

        .LegendBox {
            width: 15px;
            height: 15px;
        }
    }

    .Disabled {
        opacity: 0.5;
        color: #cccccc;
        .LegendBox {
            background-color: #cccccc !important;
            border-radius: 4px;
        }
    }
}

.LegendContainerLight {
    @extend .LegendContainer;
    color: #000;
}