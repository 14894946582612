
          @import "src/components/assets/Variables.module.scss";
        
.DatePicker {
    display: flex;
    gap: 10px;
    color: white;
    align-items: center;
    background: $background-paper;
    border-radius: 35px !important;
    border: 1px solid #707986 !important;
    width: 352px;
    box-shadow: none;

    :global {
        .ant-picker-input {
            height: 40px;
        }

        .ant-picker {
            &-input {
                input {
                    color: white;
                }
            }
            &-seperator {
                color: $primary;
            }
            &-range {
                .ant-picker-active-bar {
                    background: $primary;
                    margin-left: 20px;
                }
            }

            &-active-bar {
                background: $primary;
                margin-left: 20px;
            }
        }

        .anticon {
            color: $primary;
        }
    }
}

.DatePopUp {
    border-radius: 13px;
    border: 1px solid $border;
    background: $background-paper;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.53);
    :global {
        .ant-picker {
            &-popup {
                background: $background-paper;
            }

            &-input {
                &-placeholder {
                    background: none !important;
                }
            }

            &-panel {
                border: none !important;

                &-container {
                    background: $background-paper;
                    box-shadow: unset !important;
                }
            }

            &-header {
                color: white;
                border-bottom: none !important;

                &-view {
                    button:hover {
                        color: $primary;
                    }
                }
            }

            &-cell {
                color: $border;
                &-in-view {
                    color: white;
                }
            }

            &-content {
                th {
                    color: white;
                }
            }

            &-prev-icon {
                color: $backgroundSecondary;
            }

            &-super-prev-icon {
                color: $backgroundSecondary;
            }

            &-next-icon {
                color: $backgroundSecondary;
            }

            &-super-next-icon {
                color: $backgroundSecondary;
            }
        }

        .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
            border: 1px solid $borderGreen;
        }

        .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
            background: #9649ff;
        }

        .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
        .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
            background: #9649ff;
        }

        .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
        .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
        .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
            background: #6c00ff;
        }

        .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
        .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(
                .ant-picker-cell-range-end
            ):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
            .ant-picker-cell-inner {
            border: 1px solid white;
            background: none !important;
        }
    }
}
