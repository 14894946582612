
          @import "src/components/assets/Variables.module.scss";
        
.FormContainer {
    display: flex;
    flex-direction: column;

    :global {
        .ant-input-number,
        .ant-input {
            width: 100%;
        }

        .ant-select,
        .ant-select-selector {
            min-height: 40px !important;
        }

        .ant-form-vertical {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
        .ant-form-item {
            width: 100%;
            margin-bottom: 0;

            &-label {
                label {
                    font-family: Roboto;
                    font-size: 1.4rem !important;
                    line-height: 20px;
                    letter-spacing: 0.25px !important;
                    color: #ffffff !important;
                }
            }
        }

        input.ant-select-selection-search-input {
            height: 38px !important;
            line-height: 38px;

            &::-moz-placeholder {
                /* Mozilla Firefox 19+ */
                height: 38px !important;
                line-height: 38px;
            }
            &::-webkit-input-placeholder {
                /* Webkit */
                height: 38px !important;
                line-height: 38px;
            }
            &:-ms-input-placeholder {
                /* IE */
                height: 38px !important;
                line-height: 38px;
            }
        }

        .row {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 24px;
            .button-wrapper {
                width: 100%;
                .ant-form-item-control {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end !important;
                }
                .cancelButton {
                    margin-right: 12px;
                    border-radius: 6px;
                }

                .button-disabled {
                    cursor: not-allowed;
                }
            }
        }

        .caption-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .caption {
            font-family: Noto Sans;
            font-size: 1.4rem;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.25px;
            text-align: left;

            color: $greyShades;

            text-transform: uppercase;
        }

        .button-group {
            display: flex;
            align-items: center;
        }
    }
}
