
          @import "src/components/assets/Variables.module.scss";
        
.TableView {
    margin: 24px;

    :global {
        .ant-table-row {
            height: 64px;
        }

        .ant-table-cell {
            text-transform: capitalize;
        }
    }
}