
          @import "src/components/assets/Variables.module.scss";
        
.MetadataColumn {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.StudyId {
    color: $greyShades;
    font-size: 1.2rem;
}
