
          @import "src/components/assets/Variables.module.scss";
        
.SelectWrapper {
    .ProjectSelectBox {
        border: 1px solid $border;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
    }

    :global {


        .ant-select {
            border-radius: 4px;
            max-width:220px !important;
        }

        .ant-select-dropdown {
            width: 265px !important;
            padding: 12px 0 !important;
        }

        .ant-select-selector {
            height: 36px !important;
            border-radius: 45px !important;
            max-width:220px !important;
            min-width: 180px !important;
        }

        .ant-select-item-option-selected {
            background-color: $darkShades90 !important;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background: none !important;
            border: 1px solid white;
        }

        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border-color: $borderGreen;
        }

    }


    .CreateProjectButton {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
        padding-bottom: 6px;
    }

    .SelectItem {
        display: flex;
        align-items: center;

        .Circle {
            width: 10px;
            height: 10px;
            margin-right: 6px;
            border-radius: 50%;
            min-width: 10px;
            min-height: 10px;
        }

        .Active {
            background-color: $statusSuccess;
        }

        .InActive {
            background-color: #FFDD7D;
        }
    }
}