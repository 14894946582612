
          @import "src/components/assets/Variables.module.scss";
        
.GRichTextEditor {
    border: 1px solid $border;

    :global {
        .ql-toolbar,
        .ql-container {
            border: none !important;
        }

        .ql-toolbar {
            border-bottom: 1px solid $border !important;
        }
        .ql-header ql-picker {
            color: white !important;
        }
        .ql-formats {
            .ql-active {
                color: $primary;
                .ql-stroke {
                    stroke: $primary !important;
                }
                .ql-fill {
                    stroke: $primary !important;
                }
            }
            button {
                &:hover {
                    .ql-stroke {
                        stroke: $primary;
                    }
                    .ql-fill {
                        stroke: $primary !important;
                    }
                }
            }
        }

        .ql-picker {
            color: white !important;
            &:hover {
                color: $primary;
            }
        }
        .ql-picker-label{
            border-color: $borderLight !important;
        }
        .ql-picker-label:hover {
            .ql-stroke {
                stroke: $primary !important;
            }
            .ql-fill {
                stroke: $primary !important;
            }
        }
        .ql-picker-label.ql-active {
            color: $primary !important;
        }
        .ql-picker-label:hover {
            color: $primary !important;
        }
        .ql-picker-options {
            background-color: #191F2A !important;
            color: white !important;
            border: 1px solid $borderLight !important;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            .ql-picker-item {
                color: white ;
                &:hover {
                    color: $primary !important;
                }
                .ql-selected {
                    color: $primary;
                }
            }
        }
        .ql-editor {
            min-height: 120px;
            max-height: 480px;
            overflow-y: auto;
            color: white;
            h1,
            h2,
            h3 {
                color: white;
            }
        }
        .ql-blank::before {
            color: $greyShades !important;
        }

        .ql-snow .ql-editor img {
            max-width: 1000px;
            max-height: 520px;
        }
        .ql-stroke {
            stroke: white;
        }
        .ql-stroke-active {
            stroke: $primary;
        }
        .ql-fill {
            fill: white;
        }
        .ql-fill-active {
            fill: $primary;
        }
        .ql-tooltip {
            background-color: $backgroundCard !important;
            border: 1px solid $border !important;
            box-shadow: none !important;
            color: $white !important;
            left: 17% !important;
            top: -7% !important;

            input {
                background: $backgroundCard !important;
                border: 1px solid $border !important;
            }
            :focus-visible {
                border-color: $primary !important;
            }

            a {
                color: $white !important;
            }
        }
        .ql-picker-item.ql-selected{
            color: $white !important;
        }
    }
}
