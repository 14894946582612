
          @import "src/components/assets/Variables.module.scss";
        
.ButtonGroup {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
}

.ResetButton {
    :global {
        .ant-btn {
            border: 1px solid $primary;
            color: $white;
            border-radius: 6px;
            background-color: $background-paper !important;
            span {
                svg {
                    path {
                        fill: $white;
                    }
                }
            }
            &:hover {
                color: $primary20;
                border-color: $primary20 !important;
            }
        }
    }
}
