
          @import "src/components/assets/Variables.module.scss";
        
.CheckboxComponent {
    :global {
        span:nth-child(2) {
            font-family: Noto Sans;
            font-style: normal;
            font-size: 1.4rem;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: #ffffff;
        }

        .ant-checkbox {
            border: 1.5px solid #c5cdd9;
            border-radius: 4px;

            &-inner {
                background-color: transparent;
                border: none;
            }
            &-checked {
                background-color: $primary;
                border: 1.5px solid $primary;
                .ant-checkbox-inner::after {
                    border: 2px solid $black;
                    border-top: 0;
                    border-left: 0;
                }
            }

            &-indeterminate .ant-checkbox-inner::after {
                background-color: $primary;
            }
        }

        label {
            display: flex;
            align-items: center;
        }
    }
}

.CheckboxGroup {
    display: grid;

    label {
        display: flex;
        align-items: center;
    }

    :global {
        .ant-checkbox {
            top: unset;
        }
    }
}
