
          @import "src/components/assets/Variables.module.scss";
        
.ListItemContainer {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 100%;
    padding: 24px;
    background: $backgroundCard;
    border-radius: 7.654px;
    border: 3px solid #272C35;

    .DetailsMenu {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 32px;
        min-width: 32px;
        height: 32px;
        @extend .ControlBtnBg;
    }

    .Details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 7px;
        flex-grow: 1;

        .ModelSource {
            display: flex;
            align-items: center;
            gap: 12px;
            justify-content: flex-start;
        }

        .DetailsHeader {
            display: flex;
            gap: 24px;
        }

        .Description {
            color: $greyShades;
            margin-bottom: 14px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            hyphens: auto;
            -ms-word-break: break-all;
            word-break: break-all;
        }

        .TagRow {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .TagRowLeftSection {
                display: flex;
                gap: 8px;
                flex-wrap: wrap;
                align-items: center;
            }
            .TagRowRightSection {
                width: 592px;

                .StatsTags {
                    margin: 0;
                }
            }
        }
    }

    .ImageWrapper {
        img {
            width: 120px;
            height: 120px;
            object-fit: cover;
            border-radius: 50%;
            filter: grayscale(100%);
        }
    }

    &:hover {
        border-color: $borderGreen;
        img {
            filter: grayscale(0%);
        }
    }
}

.Access {
    text-transform: capitalize;
}



@media only screen and (max-width: 1500px) {
    .StatsTags {
        flex-direction: column;
    }

    .StatsItem {
        justify-content: start;
    }
}