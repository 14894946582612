
          @import "src/components/assets/Variables.module.scss";
        
.badgeMain {
  :global {
    .ant-badge-count {
      background-color: $statusError !important;
      box-shadow: none;
    }
  }
}
