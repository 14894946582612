
          @import "src/components/assets/Variables.module.scss";
        
.GSegmentedContainer {
    padding: 4px !important;
    background-color: #1A202B !important;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    :global {

        .ant-segmented-group {
            border-radius: 8px;
            background-color: #1A202B !important;
        }

        .ant-segmented-thumb {
            background-color: $darkShades !important;
            border-radius: 8px;
        }

        .ant-segmented-item {
            color: $white;
            font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans',
                'Helvetica Neue', sans-serif;
            letter-spacing: 0.25px;
            border-radius: 8px;

            &-selected {
                background-color: $typographyDark !important;
            }
        }
    }
}