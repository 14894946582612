
          @import "src/components/assets/Variables.module.scss";
        
@import 'components/assets/Palette.module.scss';

.MDContainer {
    overflow: auto;
    height: 750px;

    h3,
    h4 {
        color: #ffffff;
    }

    h3 {
        @extend .subtitle2-bold;
    }
    h4 {
        @extend .pBody-bold;
    }

    p {
        @extend .pBody-normal;
    }
}

.TermOfUse {
    color: $greyShades;
    margin-top: 30px;

    .Link {
        color: $primary;
        cursor: pointer;
    }

    span {
        &:hover {
            color: $secondary80;
        }
    }
}
