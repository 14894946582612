
          @import "src/components/assets/Variables.module.scss";
        
.DropdownMenu {
    :global {
        .ant-dropdown-menu {
            border: 1px solid $border;
            padding: 0px;
            color: $white !important;
            border-radius: 13px;
            background: $backgroundCard;
            box-shadow: 48px 37px 63px 0px rgba(0, 0, 0, 0.4);

            &-item {
                color: $white !important;
                margin: 0;
                padding: 12px 16px !important;

                &:last-child {
                    border-bottom: none;
                }

                &:hover {
                    background: $darkShades90 !important;
                }

                &:hover:first-child {
                    border-top-left-radius: 13px !important;
                    border-top-right-radius: 13px !important;
                }

                &:hover:last-child {
                    border-bottom-left-radius: 13px !important;
                    border-bottom-right-radius: 13px !important;
                }

                a {
                    color: $white !important;
                    gap: 12px;
                }

                svg {
                    overflow: visible;
                }
            }

            &-title-content {
                width: 100%;
                margin: 0;
                border: none;
            }
        }
    }
}
