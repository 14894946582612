
          @import "src/components/assets/Variables.module.scss";
        
.UserDropdown {
    cursor: pointer;
}

.UserDropdownOverlay {
    padding: 0;
    width: 171px;
    border-radius: 13px;
    background: #191F2A;
    box-shadow: -10px -4px 31.6px 0px rgba(0, 0, 0, 0.40), 48px 37px 63px 0px rgba(0, 0, 0, 0.40);

    :global {
        .ant-dropdown-menu {
            border: none !important;
            padding: 5px !important;
            border-radius: 13px;
            background: #191F2A !important;

            .ant-dropdown-menu-item-icon {
                width: 24px;
                margin-right: 0;
                flex-shrink: 0;
            }
        }

        .ant-dropdown-menu-item {
            padding: 5px 20px !important;
            display: flex;
            gap: 8px;
        }

        .ant-dropdown-menu-item:last-child {
            margin-top: 12px;
        }

        .ant-dropdown-menu-item:hover {
            background: #161B24 !important;
            border-radius: 5px !important;
        }

        .ant-dropdown-menu-item:hover:first-child,
        .ant-dropdown-menu-item:hover:last-child {
            border-radius: 5px !important;
        }

    }
}

.UserInfo {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid $border;
    margin-bottom: 12px;

    .UserName {
        margin-left: 12px;

        .DisplayName,
        .Email {
            font-family: Roboto;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 127px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

}

:global {
    .ant-tooltip-inner {
        max-width: 200px !important;
        border-radius: 16px !important;

        a {
            color: $white !important;
            text-decoration: underline;
        }
    }
}


.menuListItem {
    font-family: Roboto;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.25px;
}