
          @import "src/components/assets/Variables.module.scss";
        
.SelectionTable {
    width: 100%;
    margin: 0;

    .SelectionColumn {
        :global {
            .ant-radio-wrapper {
                margin: 0 auto;
            }

            .ant-radio-inner {
                background: #344055;
            }
        }
    }

    :global {
        .ant-table-thead {
                .ant-table-cell {
                    background-color: $tableHeadBg;
                }
            }
        tbody {
            tr {
                .ant-table-cell:first-of-type {
                    background-color: $tableHeadBg !important;
                }
            }
        }
    }
}