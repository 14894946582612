
          @import "src/components/assets/Variables.module.scss";
        
.GDrawer {
  :global {
    .ant-drawer-body{
      padding: 0;
    }
   .ant-drawer-content {
      background-color:$backgroundDark;
    }
    .ant-drawer-header {
      background-color:$darkShades;
      border-bottom:1px solid $border;
    }
    .ant-drawer-title{
      color: white;
    }
  }
}
