
          @import "src/components/assets/Variables.module.scss";
        
.DashboardCardContainer {
    position: relative;
    padding: 0;
    color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 7.654px;
    border: 3px solid #272c35;
    overflow: hidden;

    &:hover {
        cursor: pointer;
        border-color: $borderGreen;
    }

    .CardImage {
        width: 100%;
        text-align: center;
        position: relative;

        .StudyCount {
            position: absolute;
            padding: 8px 20px;
            gap: 4px;
            bottom: 25%;
            left: 3%;
            border-radius: 12px;
            background: rgba(59, 66, 78, 0.4);
            color: $white;
            z-index: 2;
        }

        .AnnotationIcon {
            position: absolute;
            bottom: 85px;
            left: 10px;
            display: flex;
        }

        :global {
            .ant-image {
                width: 100%;
                height: 267px;

                &-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                &-placeholder {
                    display: none;
                }
            }
        }

        .DetailsMenu {
            position: absolute;
            top: 12px;
            right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 28px;
            @extend .ControlBtnBg;

            :global {
                .ant-btn {
                    &-ant-btn-icon-only {
                        border: none;
                    }
                }
            }
        }
    }

    .CardInfoContainer {
        padding: 23px;
        display: flex;
        flex-direction: column;
        gap: 17px;
        flex-grow: 1;

        .Descripton {
            color: $greyShades !important;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            flex-grow: 1;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            -ms-word-break: break-all;
            word-break: break-all;
            word-break: break-word;
        }
    }
}

.CardInfoHeader {
    display: flex;
    align-items: flex-start;
    position: relative;
    justify-content: space-between;
}

.CardInfoHeaderMenu {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-left: 4px;
}

.Tags {
    display: flex;
    gap: 8px;
    color: $white;

    .TagItem {
        width: unset;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
        padding: 6px 15px;
        max-width: 90px;
        border-radius: 0px 16px 16px 16px;
        height: 28px;
    }
}

.TagContainer {
    display: flex;
    justify-content: space-between;
}

.Access {
    text-transform: capitalize;
    border-radius: 0px 16px 16px 16px;
    padding: 6px 15px;
    color: $black;
}
