
          @import "src/components/assets/Variables.module.scss";
        
@mixin stacked-image {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.GalleryContainer {
    min-height: 0;

    .ImageSearch {
        padding: 16px 16px 0;
        :global {
            .ant-select-selection-search-input {
                width: 80%;
            }
        }
    }
}

.ImageList,
.SeriesList {
    overflow: auto;
    height: calc(100% - 60px);
    padding: 4px 16px 16px 16px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 14px;
    margin-top: 8px;
}

.GallerySkeletonItem {
    :global {
        .ant-skeleton-image {
            height: 100px;
        }
    }
}

.StackedSkeletonItem {
    @include stacked-image;
}

.ViewNavigation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;

    .Segmented {
        background: none !important;

        :global {
            .ant-segmented-group {
                background: none !important;
            }

            .ant-segmented-thumb {
                background-color: none !important;
                border-color: $borderGreen !important;
            }

            .ant-segmented-item {
                border-bottom: 4px solid $viewerSelectBox !important;
                background-color: $viewerSelectBox !important;
                color: $white;
                font-family: Roboto;
                border-radius: 0 !important;
                transition: $borderGreen 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

                &-selected {
                    color: $primary;
                    border-color: $borderGreen !important;
                }
            }
        }
    }
}

.ActiveImageLabel {
    color: $primary !important;
}
.ActiveImageBorder {
    border: 1px solid $primary !important;
}

.Thumbnail {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.2s;
    height: 100px;
    width: 94%;
    color: white;
    position: relative;

    &:hover {
        cursor: pointer;
        color: $primary;
    }

    .Image {
        border: 1px solid $white;
        border-radius: 6px;
        height: 100px;
        object-fit: cover;

        &:hover {
            border: 1px solid $primary;
        }
    }
}

.ThumbnailWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 94%;
    color: white;
    position: relative;
    -webkit-box-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    height: 100px;
    transition: transform 0.2s ease 0s, -webkit-transform 0.2s ease 0s;

    .Image {
        border: 2px solid $white;
        border-radius: 6px;
        height: 90px;
        object-fit: cover;
    }

    &:hover {
        .Image {
            border: 2px solid $borderGreen;
        }
        cursor: pointer;
    }

    .Thumbnail {
        border-radius: 6px;
        position: absolute;
        width: 100%;

        :global {
            .ant-image {
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;;
                width: 100%;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                -webkit-box-align: center;
                filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                height: 100%;

            }

            .ant-image-img {
                position: relative;
                width: 100%;
                object-fit: cover;
                border-radius: 6px;
                height: 90px;
            }
        }

        &:hover {
            :global {
                .ant-image-img {
                    transform: scale(1.03);
                    border: 2px solid $borderGreen;
                }
            }
        }
    }
}

.ThumbnailImageWrapper {
    @extend .ThumbnailWrapper;
    height: 90px;
}

.ThumbnailContainer {
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    .ActiveSerieBorder {
        :global {
            .ant-image-img {
                border: 1px solid $primary !important;
            }
        }
    }

    .itemDesc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95%;
        margin: 8px 0 0px;
        color: $white;

        :global {
            .pBody-medium {
                display: unset;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        span {
            display: flex;
            justify-content: center;
            svg {
                path {
                    fill: $primary;
                }
            }

            :global {
                .caption2-normal {
                    margin: 4px 0 4px 8px;
                }
            }
        }
    }

    .itemDescVertical {
        @extend .itemDesc;
        flex-direction: column;
    }
}
