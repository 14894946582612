.h1-bold {
    font-weight: bold !important;
    font-size: 6rem !important;
    line-height: 70px !important;
    letter-spacing: -0.5px !important;
}

.h2-bold {
    font-weight: bold !important;
    font-size: 4.8rem !important;
    line-height: 65px !important;
}

.h3-normal {
    font-weight: normal !important;
    font-size: 3.4rem !important;
    line-height: 46px !important;
    letter-spacing: 0.25px !important;
}

.h3-fw300 {
    @extend .h3-normal;
    font-weight: 300 !important;
}

.h3-bold {
    @extend .h3-normal;
    font-weight: bold !important;
}

.h4-normal {
    font-weight: normal !important;
    font-size: 2.4rem !important;
    line-height: 33px !important;
    letter-spacing: 0.15px !important;
}

.h4-bold {
    @extend .h4-normal;
    font-weight: bold !important;
}

.h5-normal {
    font-weight: normal !important;
    font-size: 2rem !important;
    line-height: 27px !important;
    letter-spacing: 0.15px !important;
}

.h5-bold {
    @extend .h5-normal;

    font-weight: bold !important;
}

.subtitle1 {
    &-normal {
        font-weight: 400 !important;
        font-size: 1.8rem !important;
        line-height: 24px !important;
    }

    &-bold {
        @extend .subtitle1-normal;

        font-weight: 700 !important;
    }
}

.subtitle2 {
    &-normal {

        font-weight: 400 !important;
        font-size: 1.6rem !important;
        line-height: 22px !important;
        letter-spacing: 0.15px !important;
    }

    &-medium {
        @extend .subtitle2-normal;

        font-weight: 500 !important;
        letter-spacing: 0.25px !important;
    }

    &-bold {
        @extend .subtitle2-normal;

        font-weight: bold !important;
    }
}

.pBody {
    &-normal {
        font-weight: normal !important;
        font-size: 1.4rem !important;
        line-height: 20px !important;
        letter-spacing: 0.25px !important;
    }

    &-medium {
        @extend .pBody-normal;
        font-weight: 500 !important;
    }

    &-links {
        @extend .pBody-normal;

        text-decoration-line: underline !important;
    }

    &-bold {
        @extend .pBody-normal;

        font-weight: 700 !important;
    }
}

.button {
    font-size: 1.4rem !important;
    line-height: 20px !important;
    letter-spacing: 1.25px !important;

    &-normal {
        @extend .button;
        font-weight: normal !important;
    }

    &-bold {
        @extend .button;
        font-weight: bold !important;
    }
}

.caption {
    &-normal {
        font-weight: 400 !important;
        font-size: 1.3rem !important;
        line-height: 18px !important;
        letter-spacing: 0.08px !important;
    }

    &-bold {
        @extend .caption-normal;

        font-weight: 700 !important;
    }
}

.caption2 {
    &-normal {
        font-weight: 400 !important;
        font-size: 1.2rem !important;
        line-height: 16px !important;
        letter-spacing: 0.4px !important;
    }

    &-medium {
        @extend .caption2-normal;

        font-weight: 500 !important;
    }

    &-bold {
        @extend .caption2-normal;

        font-family: Roboto !important;
        font-weight: 700 !important;
    }
}

.overline {
    &-normal {
        font-weight: normal !important;
        font-size: 1rem !important;
        line-height: 12px !important;
        letter-spacing: 0.4px !important;
    }

    &-bold {
        @extend .overline-normal;

        font-weight: bold !important;
    }
}

.bold {
    font-weight: bold !important;
}

.pointer {
    cursor: pointer;
}

.cardTitle {
    color: #FFF;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.15px;
}

.pDesc {
    color: $greyShades !important;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Roboto;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px; 
    letter-spacing: 0.25px;
}