
          @import "src/components/assets/Variables.module.scss";
        
.DashboardMiniCardViewContainer {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 24px;
    margin: 0 24px 24px;

    @media only screen and (max-width: 1400px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media only screen and (max-width: 1000px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
