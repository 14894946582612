
          @import "src/components/assets/Variables.module.scss";
        
@mixin paginationButton {
    color: $greyShades !important;
    background-color: $background-paper !important;
    width: 32px;
    height: 32px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 1.2rem;
    cursor: pointer;

    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $border;
    border-radius: 18px;
}

.GList {
    :global {
        .ant-pagination {
            display: flex;
            justify-content: flex-end;
            margin-top: 16px;

            .ant-pagination-total-text {
                color: $white
            }

            .ant-pagination-prev,
            .ant-pagination-next {
                button {
                    @include paginationButton();
                }

                &.ant-pagination-disabled {
                    display: none;
                }
            }

            .ant-pagination-jump-prev,
            .ant-pagination-jump-next,
            &-item {
                @include paginationButton();

                .ant-pagination-item-ellipsis,
                a {
                    color: $greyShades !important;
                    width: 100%;
                    padding: 0;
                }
            }

            li.ant-pagination-item-active {
                background-color: $buttonPrimaryDefault !important;

                a {
                    color: $typographyDark !important;
                }
            }

            &-options {
                width: unset !important;

                .ant-select-selector {
                    background-color: $background-paper !important;
                    border: none;
                    width: 100%;
                    color: $greyShades !important;

                    .ant-select-selection-item::after {
                        display: none;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 1.2rem;
                    }
                }

                .ant-select-arrow {
                    color: $greyShades !important;
                }

                .ant-select-dropdown {
                    background: $backgroundDark !important;
                    border: 1px solid $border !important;
                    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.06);
                    padding: 10px 0px;
                    color: $white;

                    .ant-select {
                        &-item {
                            color: $white !important;

                            &:hover {
                                background: $darkShades90 !important;
                            }

                            background: $backgroundDark !important;
                        }
                    }

                    .ant-select-item-option-selected {
                        background: $darkShades90 !important;
                    }
                }
            }

            .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
                color: $iconSecondary;
            }
        }
    }
}
