
          @import "src/components/assets/Variables.module.scss";
        
.TextAreaContainer {
    :global {
        textarea,
        .ant-input {
            width: 100%;
            border: 1px solid #707986;
            display: flex;
            align-items: center !important;
            color: #ffffff !important;
            font-family: Roboto;
            font-size: 1.4rem;
            line-height: 20px;
            letter-spacing: 0.25px;
            border-radius: 10px;
            background: $backgroundCard !important;
            padding: 4px 15px;

            &::placeholder {
                color: var(--typography-color-grey, $greyShades);
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
                letter-spacing: 0.25px;
            }
        }
        .ant-input-textarea-show-count::after {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.4px;
            color: $greyShades;
            margin-top: 4px;
        }
    }
}
