
          @import "src/components/assets/Variables.module.scss";
        
.AutoCompleteWrapper {
    display: grid;
    width: 100%;
    position: relative;
    :global {
        .ant-select {
            width: 100%;

            &-selector {
                background: $backgroundCard !important;
                border: 1px solid #707986 !important;
                border-radius: 50px !important;
                color: $white;
                width: 100% !important;
                height: 48px;
                &:hover {
                    background-color: $backgroundSecondary;
                }
            }

            &-clear {
                background: none;
                right: 45px;
            }

            &-single:not(.ant-select-customize-input) .ant-select-selector {
                padding: 0 15px;
            }

            &-single .ant-select-selector .ant-select-selection-search {
                right: 15px;
                left: 15px;
            }

            &-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
                box-shadow: none;
            }

            &-selection-placeholder {
                font-size: 12px;
                font-weight: normal;
            }
        }

        .anticon-close-circle {
            color:$borderRed;
        }
    }
}

.DropdownWrapper {
    background: $backgroundCard !important;
    border: 1px solid $border !important;
    color: $white;
    padding: 0;
    border-radius: 13px;
    box-shadow: 48px 37px 63px 0px rgba(0, 0, 0, 0.4);

    :global {
        .ant-select {
            &-item {
                color: $white !important;

                &:hover {
                    background: $darkShades90 !important;
                }
                background: $backgroundCard !important;
            }
        }
        .ant-empty {
            color: $white;
        }
    }
}

.searchBox {
    display: grid;
    grid-column-start: 1;
    grid-row-start: 1;
}
.searchIcon {
    display: grid;
    grid-column-start: 1;
    grid-row-start: 1;
    z-index: 9;
    justify-self: flex-end;
    align-self: center;
    margin-right: 15px;
}
