
          @import "src/components/assets/Variables.module.scss";
        
.PlatformAlertModal {

    .ModalHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding-bottom: 10px;
        border-bottom: 1px solid #707986;
    }

    .CloseButton {
        background: #161B24;
    }
}