
          @import "src/components/assets/Variables.module.scss";
        
.AuthCardContainer {
    padding: 30px;
    width: 100%;
    @extend .CardBg;
    display: flex;
    flex-direction: column;
    align-items: center;

    .AuthCardCaption {
        margin-bottom: 10px;

        div {
            text-align: center;
        }
    }
}

@media(max-width: 1200px) {
    .AuthCardContainer {
        width: 80%;
    }
}