
          @import "src/components/assets/Variables.module.scss";
        
.EmptyPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 12px auto;
    padding: 24px;

    .EmptyPageTitle {
        width: 100%;
        padding: 0px 12px;
        text-align: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 32px;
        letter-spacing: 0.215436px;
    }

    .EmptyPageImage {
        height: 40px;
    }
}
