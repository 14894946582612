$primary-bgColor: $primary;
$primary-bgHoverColor: $buttonPrimaryHover;
$primary-bgFocusColor: $primary; //not in palette check it again
$primary-bgDisabledColor: $buttonPrimaryDisabled;
$primary-textColor: $black;
$primary-textHoverColor: $black;
$primary-textDisabledColor: $typographyDisabled;
$primary-disabledBorderColor: $buttonPrimaryDisabled;

$default-bgColor: $darkShades;
$default-bgHoverColor: $background-paper;
$default-bgFocusColor: $darkShades;
$default-bgDisabledColor: rgba(59, 66, 78, 0.12);
$default-textColor: $white;
$default-textHoverColor: $white;
$default-textDisabledColor: $typographyDisabled;
$default-disabledBorderColor: $borderDisabled;

$ghost-bgColor: $statusError;
$ghost-bgHoverColor: $darkShades;
$ghost-bgFocusColor: $statusError;
$ghost-bgDisabledColor: $darkShades;
$ghost-textColor: $white;
$ghost-textHoverColor: $statusError;
$ghost-textDisabledColor: rgba(253, 75, 80, 0.48);
$ghost-disabledBorderColor: rgba(253, 75, 80, 0.48);

@mixin btn-color($bgColor, $bgHoverColor, $bgFocusColor, $bgDisabledColor, $textColor, $textHoverColor, $textDisabledColor) {
    cursor: pointer;
    background-color: $bgColor;
    color: $textColor;

    &:hover {
        background-color: $bgHoverColor;
        color: $textHoverColor !important;
    }

    &:active {
        background-color: $bgFocusColor;
    }

    &:disabled {
        background-color: $bgDisabledColor !important;
        color: $textDisabledColor !important;
        cursor: not-allowed;
        caret-color: transparent;
        span {
            svg {
                path {
                    fill: $textDisabledColor;
                }
            }
        }
    }

    &:focus {
        outline: none;
        background-color: $primary;
        color: $black;
    }
}

.GButton {
    color: $white;
    :global {
        .ant-btn {
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            height: fit-content;
            caret-color: transparent;
            border: none;
            border-radius: 40px;

            .end-icon {
                margin-right: 8px;
                display: flex;
            }

            .text {
                display: flex;
                align-items: center;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px; /* 142.857% */
                letter-spacing: 1.25px;

                &:disabled {
                    color: $white !important;
                    cursor: not-allowed !important;
                }
            }

            &-circle {
                border-radius: 50% !important;
            }

            &-circle.middle {
                height: 32px !important;
                width: 32px !important;
                padding: 0 !important;
            }
            &-circle.large {
                height: 40px !important;
                width: 40px !important;
                padding: 0 !important;
            }

            &-circle.middle.default {
                background: $background-paper !important;
                &:hover {
                    background: $greyShades10 !important;
                    svg {
                        path {
                            fill: #364153;
                        }
                    }
                }
            }
        }

        .ant-btn:disabled:hover {
            color: $typographyDisabled;
        }
        .ant-btn:disabled:focus,
        .ant-btn:disabled:active {
            cursor: not-allowed;
            background-color: $primary;
        }

        .primary {
            @include btn-color(
                $primary-bgColor,
                $primary-bgHoverColor,
                $primary-bgFocusColor,
                $primary-bgDisabledColor,
                $primary-textColor,
                $primary-textHoverColor,
                $primary-textDisabledColor
            );
            border: none;
            .middle {
                padding: 10px 24px !important;
                span {
                    svg {
                        path {
                            fill: $black;
                        }
                    }
                }
            }
        }

        .default {
            @include btn-color(
                $default-bgColor,
                $default-bgHoverColor,
                $default-bgFocusColor,
                $default-bgDisabledColor,
                $default-textColor,
                $default-textHoverColor,
                $default-textDisabledColor
            );
            border: 1px solid $border;
            &.ant-btn {
                transition: none;
            }
            &.ant-btn:hover {
                border: 1px solid $default-bgHoverColor !important;
            }
            &:disabled {
                border: 1px solid $default-disabledBorderColor !important;
            }
            &:focus,
            &:active {
                background-color: transparent !important;
                border: 1px solid $border !important;
                color: $white;
                box-shadow: none !important;
            }
        }

        .ghost {
            @include btn-color(
                $ghost-bgColor,
                $ghost-bgHoverColor,
                $ghost-bgFocusColor,
                $ghost-bgDisabledColor,
                $ghost-textColor,
                $ghost-textHoverColor,
                $ghost-textDisabledColor
            );
            border: 1px solid $statusError;
            &:hover {
                border: 1px solid $statusError !important;
            }
            &:disabled {
                border: 1px solid $ghost-disabledBorderColor !important;
                &:hover {
                    color: $ghost-textDisabledColor !important;
                }
            }
            &:focus {
                background-color: $ghost-bgColor !important;
                color: $ghost-textColor;
                border: none;
                &:hover {
                    background-color: $ghost-bgHoverColor !important;
                    color: $ghost-textHoverColor !important;
                    border: 1px solid $statusError !important;
                }
            }
            .middle {
                padding: 10px 24px !important;
                span {
                    svg {
                        path {
                            fill: $white;
                        }
                    }
                }
            }
        }

        .dashed {
            background: none;
            border: 1px dashed $border;

            &:hover,
            &:focus,
            &:active {
                border: 1px dashed $secondary100;
                color: $secondary100;
                background: transparent;
            }
            &:disabled {
                border: 1px dashed $secondary80;
                color: $secondary80;
                background: none;

                &:hover {
                    border: 1px dashed $secondary80;
                    color: $secondary80;
                    background: none;
                }
            }
        }

        .large {
            padding: 14px 24px;
            height: 48px;
            span {
                svg {
                    path {
                        fill: $black;
                    }
                }
            }
        }
        .middle {
            padding: 10px 24px !important;
            height: 40px;
        }
        .small {
            padding: 6px 24px;
            height: 32px;
            div {
                height: 20px;
            }

            span {
                svg {
                    path {
                        fill: $black;
                    }
                }
            }
        }
    }
}
