
          @import "src/components/assets/Variables.module.scss";
        
.Background {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    cursor: pointer;

    &:hover {
        path {
            fill: $primary;
        }
    }
}