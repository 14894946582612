
          @import "src/components/assets/Variables.module.scss";
        
@mixin stacked-image {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

@mixin paginationButton {
    color: $greyShades !important;
    background-color: $background-paper !important;
    width: 32px;
    height: 32px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 1.2rem;
    cursor: pointer;

    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $border;
    border-radius: 18px;
}

.DatasetExplorer {
    :global {
        .truncate {
            width: 200px;
        }
        .ant-empty {
            color: $white;
        }

        .ant-pagination {
            display: flex;
            justify-content: flex-end;
            margin-top: 16px;

            .ant-pagination-total-text {
                color: $white
            }

            .ant-pagination-prev,
            .ant-pagination-next {
                button {
                    @include paginationButton();
                }

                &.ant-pagination-disabled {
                    display: none;
                }
            }

            .ant-pagination-jump-prev,
            .ant-pagination-jump-next,
            &-item {
                @include paginationButton();

                .ant-pagination-item-ellipsis,
                a {
                    color: $greyShades !important;
                    width: 100%;
                    padding: 0;
                }
            }

            li.ant-pagination-item-active {
                background-color: $buttonPrimaryDefault !important;

                a {
                    color: $typographyDark !important;
                }
            }

            &-options {
                width: unset !important;

                .ant-select-selector {
                    background-color: $background-paper !important;
                    border: none;
                    width: 100%;
                    color: $greyShades !important;

                    .ant-select-selection-item::after {
                        display: none;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 1.2rem;
                    }
                }

                .ant-select-arrow {
                    color: $greyShades !important;
                }

                .ant-select-dropdown {
                    background: $backgroundDark !important;
                    border: 1px solid $border !important;
                    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.06);
                    padding: 10px 0px;
                    color: $white;

                    .ant-select {
                        &-item {
                            color: $white !important;

                            &:hover {
                                background: $darkShades90 !important;
                            }

                            background: $backgroundDark !important;
                        }
                    }

                    .ant-select-item-option-selected {
                        background: $darkShades90 !important;
                    }
                }
            }

            .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
                color: $iconSecondary;
            }
        }
    }

    .ImageContainer {
        width: 100%;
        height: 140px;
        text-align: center;
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: center;

        .ImageSelection {
            position: absolute;
            z-index: 100;
            top: 8px;
            left: 8px;
        }

        :global {
            .ant-image {
                width: 100%;
                height: 100%;

                &-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border: 1px solid $borderLight;
                    border-radius: 6px;
                }
            }
        }

        &:hover {
            :global {
                .ant-image-img {
                    border-color: $borderGreen;
                }
            }
        }
    }

    .StackedThumbnail {
        margin-bottom: 18px;
        .ThumbnailSkeleton {
            @include stacked-image;
            width: 100%;
            height: 100%;
        }
        :global {
            .ant-image {
                @include stacked-image;

                .ant-image-img {
                    position: relative;
                }
            }

            .ant-skeleton,
            .ant-skeleton-image {
                height: 100%;
            }
        }
    }
}

.BreadcrumbContainer {
    .BreadcrumbLink {
        color: $white;
        cursor: pointer;
    }
    :global {
        .anticon-down,
        .ant-breadcrumb-separator,
        .ant-breadcrumb-link {
            color: $white;
        }
    }
    margin-bottom: 24px;
}

.BreadcrumbMenu {
    background: $backgroundDark;
    border: 1px solid $border;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    padding: 10px 0px;
    color: $white !important;
    :global {
        .ant-dropdown-menu-item {
            color: $white !important;
            margin: 0;
            padding: 12px 16px !important;
            border-bottom: 1px solid $border;

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                background: $darkShades90;
            }

            &-active {
                background: $darkShades90;
            }

            a {
                color: $white !important;
                gap: 12px;
            }

            svg {
                overflow: visible;
            }
        }

        .ant-dropdown-title-content {
            width: 100%;
            margin: 0;
            border: none;
        }
    }
}

.HeaderContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .Search {
        width: 200px;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 12px;
        gap: 12px;

        :global {
            .ant-input {
                height: 40px !important;
            }
        }
    }
}
