
          @import "src/components/assets/Variables.module.scss";
        
.MenuContainer {
    background: none;
    color: $white;
    border-bottom: none;

    :global {
        .ant-menu-item,
        .ant-menu-submenu {
            display: flex;
            align-items: center;
            transition: width 0.5s !important;

            &-active,
            &-selected,
            &:after,
            &:hover {
                color: $white !important;
                border-radius: 0px;
            }
        }

        .ant-menu-title-content {
            color: $white;
            line-height: 19px;
        }

        li {
            transition: width 0.5s !important;
        }

        .ant-menu-item:after,
        .ant-menu-submenu:after {
            content: '';
            display: block;
            height: 3px;
            width: 0;
            background: transparent;
            transition: width 0.5s, background-color 0.5s !important;
        }
        .ant-menu-item:hover:after,
        .ant-menu-submenu:hover:after {
            width: 85%;
            background: white;
            transition: width 0.5s !important;
        }

        .ant-menu-item-selected:after,
        .ant-menu-submenu-selected:after {
            width: 85%;
            background: white;
            height: 3px;
            content: '';
            display: block;
        }
    }
}

:global {
    .ant-menu-submenu-popup {
        .ant-menu {
            border: 1px solid $border;
            color: $white;
            border-radius: 13px;
            background: $backgroundCard;
            box-shadow: 48px 37px 63px 0px rgba(0, 0, 0, 0.4);

            .ant-menu-submenu {
                margin-bottom: 8px;
                svg path {
                    fill: #fff;
                }
                &-arrow {
                    color: #fff;
                }
                &-title {
                    display: flex;
                    align-items: center;
                    width: 252px;
                }
                &:hover {
                    background: $darkShades90;
                    .ant-menu-submenu-title {
                        &:hover {
                            color: #fff;
                        }

                        .ant-menu-submenu-arrow {
                            color: $primary;
                        }
                    }
                }

                &-selected {
                    background: $backgroundCard;
                    .ant-menu-title-content {
                        color: $primary;
                    }

                    svg {
                        path {
                            fill: $primary !important;
                        }
                    }
                }
            }

            .ant-menu-item {
                font-family: 'Noto Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.4px;
                display: flex;
                align-items: center;
                width: 252px;

                &-title {
                    display: flex;
                }

                &:hover {
                    background: $darkShades90;
                }

                &-selected {
                    background: $backgroundCard;
                    .ant-menu-title-content a {
                        color: $primary;
                    }

                    svg {
                        path {
                            fill: $primary;
                        }
                    }
                }
            }
        }
    }

    .ant-menu-vertical .ant-menu-item,
    .ant-menu-vertical-left .ant-menu-item,
    .ant-menu-vertical-right .ant-menu-item,
    .ant-menu-inline .ant-menu-item,
    .ant-menu-vertical .ant-menu-submenu-title,
    .ant-menu-vertical-left .ant-menu-submenu-title,
    .ant-menu-vertical-right .ant-menu-submenu-title,
    .ant-menu-inline .ant-menu-submenu-title {
        margin-top: 0;
        margin-bottom: 0;
    }
}
