
          @import "src/components/assets/Variables.module.scss";
        
@mixin Title {
    font-family: Roboto;
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 32px;
}
.GMessageModal{
    width: auto !important;
}

.MessageModalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;

    :global {
        .pBody-normal {
            color: #ffffff;
            margin-top: 12px;

            text-align: center;
        }
        .pBody-small {
            color: #ffffff;
            margin-top: 12px;
            font-size: 1.2rem;
            text-align: center;
            letter-spacing: 0.25px;
            font-weight: 400;
            padding:0 20px;
        }

    }

    .success {
        @include Title;
        color: $statusSuccess;
    }

    .warning{
        @include Title;
        color: $statusWarning
    }

    .danger{
        button{
            background-color: #e04348!important;
            &:hover {
                background-color:  $statusError!important;
              }
              &:active {
                background-color: #e04348c8!important;
              }
        }
    }

    .ButtonGroup{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 12px;   
        margin-top: 12px;
        width: 100%;
        .BlockButton{
            width: 100%;
        }
    };
}
